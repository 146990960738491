<template>
  <form class="container" @submit.prevent="update">
    <div class="w-100 text-right">
      <button
        type="button"
        title="التعديل"
        class="btn btn-sm btn-info nr-shadow"
        v-if="!editing"
        @click="changeMode(true)"
      >
        <i class="fas fa-pen"></i>
      </button>
      <div v-else>
        <button
          type="button"
          title="إلغاء"
          class="btn btn-sm btn-danger nr-shadow"
          @click="changeMode(false)"
        >
          <i class="fa fa-times"></i>
        </button>
        <button
          type="submit"
          title="حفظ"
          class="btn btn-sm btn-success nr-shadow"
        >
          <i class="fa fa-check"></i>
        </button>
      </div>
    </div>
    <div class="row" v-if="!!auction">
      <div class="col-sm-12">
        <AuctionData
          :auction="auction"
          :editing="editing"
          @models-changed="setModels"
        />
      </div>
    </div>
    <confirm-model
      target-id="auction-errors"
      confirm-btn="d-none"
      cancel-text="تأكيد"
      cancel-btn="btn-info"
      :modal-lg="true"
    >
      <div class="alert alert-danger py-3">
        <h5 class="text-center">لا يمكن تعديل المزاد</h5>
        <ol>
          <li v-for="error in errors" :key="error">
            <p>{{ error }}</p>
          </li>
        </ol>
      </div>
    </confirm-model>
  </form>
</template>

<script>
import AuctionData from "@/components/Auctions/AuctionData";
import cars from "@/mixins/cars";
import Storage from "@/common/Storage";
import types from "@/common/types";
import ConfirmModel from "@/components/UI/ConfirmModel";

export default {
  components: { ConfirmModel, AuctionData },
  mixins: [cars],
  data() {
    return {
      auction: "",
      editing: false,
      showLog: false,
      errors: [],
    };
  },
  computed: {
    auctionId() {
      return this.$route.params.auction;
    },
  },
  methods: {
    changeMode(mode) {
      this.editing = mode;
    },
    async setAuction() {
      if (!!this.auctionId) {
        const response = await http.send(`auctions/${this.auctionId}`, {
          _method: "GET",
        });
        if (http.responseAccepted(response))
          this.auction = response.data.auction;
      }
    },
    validSelectedCar(model) {
      if (
        !this.models ||
        this.models.length === 0 ||
        !this.auction ||
        !this.auction.model
      )
        return true;
      return this.models.find((car) => {
        return (
          parseInt(car.id) === parseInt(model) ||
          car.content === this.auction.model
        );
      });
    },
    validArea(selectedArea) {
      const areas = this.$store.getters.areas;
      if (
        !areas ||
        areas.length === 0 ||
        !this.auction ||
        !this.auction.area_id
      )
        return true;
      return areas.find((area) => {
        return (
          parseInt(area.id) === parseInt(selectedArea) ||
          parseInt(area.id) === parseInt(this.auction.area_id)
        );
      });
    },
    setModels(models) {
      this.models = models;
    },
    async update(e) {
      const formData = new FormData(e.target);

      if (!this.validSelectedCar(formData.get("model"))) {
        return http.errorMessage("نوع المركبة غير متطابق مع الموديل");
      }

      if (!this.validArea(formData.get("area_id"))) {
        return http.errorMessage("المحافظة غير متطابقة مع المنطقة");
      }

      if (
        types.checkY(formData.get("transferred")) &&
        !formData.get("city_id") && !this.auction.city_id
      ) {
        return http.errorMessage(
          "يرجى تحديد المحافظة في حال نقل المركبة بونش المزاد"
        );
      }

      formData.set("_method", "PUT");
      const response = await http.send(`auctions/${this.auction.id}`, formData);
      if (http.responseAccepted(response)) {
        if (!!response.data.errors) {
          this.errors = response.data.errors;
          this.showModal("#auction-errors");
        } else {
          Storage.removeStartWith("auction-categories-");
          this.auction = response.data.auction;
          this.editing = false;
          http.popupMessage("success", messages.updated);
        }
      }
    },
    setShowLog() {
      this.showLog = !this.showLog;
    },
  },
  async created() {
    await this.setAuction();
  },
};
</script>
